import React, { useState, useEffect } from "react";
import Navigation from "../components/Navigation";
import bureautique from "../assets/bureautique.jpg";
import lan from "../assets/lan.jpg";
import maint from "../assets/maintenancereseau.jpg";

const Reseau = (props) => {

//=========================================================
//  Détection 'escape' et traitement 
//=========================================================

  const [isEscape, setIsEscape] = useState(false);

  useEffect(() => {
    const handleEsc = (event) => {
       if (event.keyCode === 27) {
        setIsEscape(true);
      }
    };
    window.addEventListener('keydown', handleEsc);
  
    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, []);
  
  const backModule = () => {
    setTimeout(() => {
      props.history.push("/");
    }, 100);
  }

  const tailleEcran = () => {
    return (window.innerWidth > 576 ? true : false )
}

//=========================================================

  return (
    <div className="home">
      <Navigation />
      {isEscape && backModule()}
      {tailleEcran() &&
      <div className="container-fluid">
        <div className="row pageTexte">
          <div className="col-2"></div>
          <div className="col-8">
            <h1>BUREAUTIQUE ET RESEAU</h1>
          </div>
        </div>
        <div className="row pageItem">
          <div className="col-2"></div>
          <div className="col-7">
            <table className="container">
              <tbody>
                <tr>
                  <td className="imageBlock">
                    <img className="imageDev" src={bureautique} alt="bureau" />
                  </td>
                  <td>
                    <p className="textTitre">Bureautique</p>
                    <p className="retraitTexte">
                      • installation d'OS (Windows, Mac, Linux) et de suites bureautique<br />
                      • formation aux logiciels de bureautique<br />
                      • support et maintenance<br />
                      <br />
                      <br />
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
            <br />
            <table className="container">
              <tbody>
                <tr>
                <td className="imageBlock">
                    <img className="imageDev" src={lan} alt="lan" />
                  </td>
                  <td>
                    <p className="textTitre">Installation de réseaux locaux</p>
                    <p className="retraitTexte">
                      • installation de box internet<br />
                      • installation des équipements de réseau: switch, router, wifi, CPL<br />
                      • installation de serveurs de fichiers<br />
                      <br />
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
            <br />
            <table className="container">
              <tbody>
                <tr>
                <td className="imageBlock">
                    <img className="imageDev" src={maint} alt="maint" />
                  </td>
                  <td>
                    <p className="textTitre">Administration de réseau</p>
                    <p className="retraitTexte">
                      • maintenance de réseau<br />
                      • accès à distance<br />
                      • sécurité des réseaux: pare-feu, antivirus, anti-espions<br />
                      <br />
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      }
      {!tailleEcran() &&
      <div className="container-fluid">
        <div className="row pageTexte">
          <div className="col-12">
            <h1>BUREAUTIQUE ET RESEAU</h1>
          </div>
        </div>
        <div className="row pageItem2">
          <div className="col-12">
            <table className="container2">
              <tbody>
                <tr>
                  <td className="imageBlock">
                    <img className="imageDev" src={bureautique} alt="bureau" />
                  </td>
                  <td>
                    <p className="textTitre">Bureautique</p>
                    <p className="retraitTexte2">
                      • installation d'OS (Windows, Mac, Linux) et de suites bureautique<br />
                      • formation aux logiciels de bureautique<br />
                      • support et maintenance<br />
                      <br />
                      <br />
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
            <br />
            <table className="container2">
              <tbody>
                <tr>
                <td className="imageBlock">
                    <img className="imageDev" src={lan} alt="lan" />
                  </td>
                  <td>
                    <p className="textTitre2">Installation de réseaux locaux</p>
                    <p className="retraitTexte">
                      • installation de box internet<br />
                      • installation des équipements de réseau: switch, router, wifi, CPL<br />
                      • installation de serveurs de fichiers<br />
                      <br />
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
            <br />
            <table className="container2">
              <tbody>
                <tr>
                <td className="imageBlock">
                    <img className="imageDev" src={maint} alt="maint" />
                  </td>
                  <td>
                    <p className="textTitre">Administration de réseau</p>
                    <p className="retraitTexte2">
                      • maintenance de réseau<br />
                      • accès à distance<br />
                      • sécurité des réseaux: pare-feu, antivirus, anti-espions<br />
                      <br />
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      }
    </div>
  );
};

export default Reseau;
