import React, { useState, useEffect } from "react";
import Navigation from "../components/Navigation";
import devinfo from "../assets/developper-informatique-internet.jpg";
import blk from "../assets/blockchain dev.jpg";
import sgbd from "../assets/sgbd.jpg";
import excel from "../assets/vba excel.jpg";
    
const Developpement = (props) => {

//=========================================================
//  Détection 'escape' et traitement 
//=========================================================

    const [isEscape, setIsEscape] = useState(false);

    useEffect(() => {
        const handleEsc = (event) => {
            if (event.keyCode === 27) {
                setIsEscape(true);
            }
        };
        window.addEventListener('keydown', handleEsc);

        return () => {
            window.removeEventListener('keydown', handleEsc);
        };
    }, []);

    const backModule = () => {
        setTimeout(() => {
            props.history.push("/");
        }, 100);
    }

    const tailleEcran = () => {
        return (window.innerWidth > 576 ? true : false )
    }
      
//=========================================================

    return (
        <div className="home">
            <Navigation />
            {isEscape && backModule()}
            {tailleEcran() &&
            <div className="container-fluid">
                <div className="row pageTexte">
                    <div className="col-2"></div>
                    <div className="col-8">
                        <h1>DOMAINES D'EXPERTISE</h1>
                    </div>
                </div>
                <div className="row pageItem">
                    <div className="col-2"></div>
                    <div className="col-7">
                        <table className="container">
                            <tbody>
                                <tr>
                                    <td className="imageBlock">
                                        <img className="imageDev" src={devinfo} alt="dev" />
                                    </td>
                                    <td>
                                        <p className="textTitre">Développement de sites web</p>
                                        <p className="retraitTexte">
                                            • développements de sites web en Angular, React ou Vue<br />
                                            • déploiement des sites dans le cloud<br />
                                            • données gérées dans des bases de données dans le cloud<br />
                                        </p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <br />
                        <table className="container">
                            <tbody>
                                <tr>
                                    <td className="imageBlock">
                                        <img className="imageDev" src={blk} alt="blk" />
                                    </td>
                                    <td>
                                        <p className="textTitre">Déploiement de blockchains privées</p>
                                        <p className="retraitTexte">
                                            • développement de blockchains privées Ethereum ou HyperLedger Fabric<br />
                                            • développement de smart contracts en solidity ou chaincode<br />
                                        </p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <br />
                        <table className="container">
                            <tbody>
                                <tr>
                                    <td className="imageBlock">
                                        <img className="imageDev" src={sgbd} alt="bdd" />
                                    </td>
                                    <td>
                                        <p className="textTitre">Base de données</p>
                                        <p className="retraitTexte">
                                            • MySQL<br />
                                            • NoSQL<br />
                                        </p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <br />
                        <table className="container">
                            <tbody>
                                <tr>
                                    <td className="imageBlock">
                                        <img className="imageDev" src={excel} alt="vba" />
                                    </td>
                                    <td>
                                        <p className="textTitre">Développement d'applications VBA Excel</p>
                                        <p className="retraitTexte">
                                            • création d'applications Excel par le développement de macros VBA<br />
                                            <br />
                                        </p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            }
            {!tailleEcran() &&
            <div className="container-fluid">
                <div className="row pageTexte">
                    <div className="col-12">
                        <h1>DOMAINES D'EXPERTISE</h1>
                    </div>
                </div>
                <div className="row pageItem2">
                    <div className="col-12">
                        <table className="container2">
                            <tbody>
                                <tr>
                                    <td className="imageBlock">
                                        <img className="imageDev" src={devinfo} alt="dev" />
                                    </td>
                                    <td>
                                        <p className="textTitre">Développement de sites web</p>
                                        <p className="retraitTexte2">
                                            • développements de sites web en Angular, React ou Vue<br />
                                            • déploiement des sites dans le cloud<br />
                                            • données gérées dans des bases de données dans le cloud<br />
                                        </p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <br />
                        <table className="container2">
                            <tbody>
                                <tr>
                                    <td className="imageBlock">
                                        <img className="imageDev" src={blk} alt="blk" />
                                    </td>
                                    <td>
                                        <p className="textTitre">Déploiement de blockchains privées</p>
                                        <p className="retraitTexte2">
                                            • développement de blockchains privées Ethereum ou HyperLedger Fabric<br />
                                            • développement de smart contracts en solidity ou chaincode<br />
                                        </p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <br />
                        <table className="container2">
                            <tbody>
                                <tr>
                                    <td className="imageBlock">
                                        <img className="imageDev" src={sgbd} alt="bdd" />
                                    </td>
                                    <td>
                                        <p className="textTitre">Base de données</p>
                                        <p className="retraitTexte2">
                                            • MySQL<br />
                                            • NoSQL<br />
                                        </p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <br />
                        <table className="container2">
                            <tbody>
                                <tr>
                                    <td className="imageBlock">
                                        <img className="imageDev" src={excel} alt="vba" />
                                    </td>
                                    <td>
                                        <p className="textTitre">Développement d'applications VBA Excel</p>
                                        <p className="retraitTexte2">
                                            • création d'applications Excel par le développement de macros VBA<br />
                                            <br />
                                        </p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            }
        </div>
    );
};

export default Developpement;
