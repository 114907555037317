import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from './pages/Home';
import Contact from './pages/Contact';
import Developpement from './pages/Developpement';
import Gestiondeprojet from './pages/Gestiondeprojet';
import Audit from './pages/Audit';
import Reseau from './pages/Reseau';
import Cgu from './pages/Cgu';
import Clients from './pages/Clients';
import ClientsCreate from './pages/ClientsCreate';
import ClientsUpdate from './pages/ClientsUpdate';
import Messages from './pages/Messages';
import Mentions from './pages/Mentions';
import Reponse from './pages/Reponse';
import Login from './components/Login';
import Signup from './components/Signup';
import Logout from './components/Logout';
import ForgetPassword from './components/ForgetPassword';

const App = () => {
  return (
    <Router>
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/contact" exact component={Contact} />
        <Route path="/developpement" exact component={Developpement} />
        <Route path="/gestiondeprojet" exact component={Gestiondeprojet} />
        <Route path="/audit" exact component={Audit} />
        <Route path="/reseau" exact component={Reseau} />
        <Route path="/cgu" exact component={Cgu} />
        <Route path="/clients" exact component={Clients} />
        <Route path="/clientsCreate" exact component={ClientsCreate} />
        <Route path="/clientsUpdate" exact component={ClientsUpdate} />
        <Route path="/messages" exact component={Messages} />
        <Route path="/mentions" exact component={Mentions} />
        <Route path="/reponse" exact component={Reponse} />
        <Route path="/login" component={Login} />
        <Route path="/signup" component={Signup} />
        <Route path="/logout" component={Logout} />
        <Route path="/forgetpassword" component={ForgetPassword} />
      </Switch>
    </Router>
  )
}

export default App;