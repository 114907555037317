import React, { useState, useEffect, useContext } from "react";
import Navigation from "../components/Navigation";
import { FirebaseContext } from '../components/Firebase';
import { withRouter } from "react-router";
import emailjs from 'emailjs-com';

const Reponse = (props) => {

//=========================================================
//  Data
//=========================================================
  
  let userData = props.history.location.state.userData;

  const firebase = useContext(FirebaseContext);

  const [email, setEmail] = useState(userData.email);
  const [objet, setObjet] = useState(userData.objet);
  const [message, setMessage] = useState("");
  const [isEscape, setIsEscape] = useState(false);

//=========================================================
//   Data de connexion à EmailJS
//=========================================================
  
  const service_EmailJS = "service_g365wcp";
  const template_EmailJS = "template_80k4bhu";
  const user_EmailJS = "user_r28zznke6kimtGSuu2zAa";

  let templateParams = {
    firstname: "AZTechInnov",
    name: "",
    company: "",
    phone: "",
    email: email,
    objet: objet,
    message: message
  };

  const reponseA = "À: " + email;
  const reponseObjet = "Re: réponse à " + objet;

//=========================================================
//  Détection 'escape' et traitement 
//=========================================================

  useEffect(() => {
    const handleEsc = (event) => {
      if (event.keyCode === 27) {
        setIsEscape(true);
      }
    };
    window.addEventListener('keydown', handleEsc);

    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, []);

  const backModule = () => {
    setTimeout(() => {
      props.history.push("/messages", {userData: email});
    }, 100);
  }

//=========================================================
//  Fonctions
//=========================================================

  const createMessage = (email, reponseObjet, message) => {

    let currentDate = new Date().toLocaleString() + "";
    let univDate = currentDate.substring(6,10)+'/'+currentDate.substring(3,5)+'/'+currentDate.substring(0,2)+currentDate.substring(10);
    firebase.addMessage(email, univDate, currentDate, reponseObjet, message);
  }

  const failMessage = (message) => {
    let formMess = document.querySelector('.form-message');

    formMess.innerHTML = message;
    formMess.style.opacity = 1;
    formMess.style.background = "#FD5757";
    formMess.style.textAlign = "center";

    document.getElementById('message').classList.add('error');
  }

  const successMessage = () => {
    let formMess = document.querySelector('.form-message');

    formMess.innerHTML = 'Message envoyé!';
    formMess.style.opacity = 1;
    formMess.style.background = '#00c1ec';
    formMess.style.textAlign = "center";

    document.getElementById('message').classList.remove('error');

    setTimeout(() => {
      formMess.style.opacity = 0;
      props.history.push('/messages', {userData: email})
    }, 5000);
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    if (message) {
      createMessage(email, reponseObjet, message);

      console.log("Réponse ", email, " ", objet, " ", message);

      successMessage();

//  Il est inutile d'utiliser emailJS: le message serait envoyé à aztechinnov@free.fr et non au destinataire final 'email'      
//    emailjs.send(service_EmailJS, template_EmailJS, templateParams, user_EmailJS)
//        .then(function (response) {
//          setEmail('');
//          setObjet('');
//          setMessage('');
//        }, function (error) {
//          failMessage("Une erreur s'est produite, veuillez réessayer");
//          props.history.push('/messages', {userData: email})
//        });
    } else {
      failMessage('Merci de remplir correctement les champs requis *')
      props.history.push('/messages', {userData: email})
    }

  }

  const envoiButton = message === ''
    ? <input className="no-button" type="button" value="Répondre" onClick={handleSubmit} disabled />
    : <input className="button" type="button" value="Répondre" onClick={handleSubmit} />

//=========================================================

  return (
    <div className="reponse">
      <Navigation />
      {isEscape && backModule()}
      <form className="contact-form pageItem" onSubmit={handleSubmit}>
        <h2>Réponse à Message</h2>
        <div className="form-content">
          <input
            type="text"
            id="email"
            name="email"
            value={reponseA}
            readOnly="readOnly"
          />
          <input
            type="text"
            id="objet"
            name="objet"
            value={reponseObjet}
            readOnly="readOnly"
          />
          <textarea
            id="message"
            name="message"
            onChange={(e) => setMessage(e.target.value)}
            placeholder="message *"
            value={message}
            maxLength="1000"
          />
        </div>
        {envoiButton}
        <div className="form-message"></div>
      </form>
    </div>
  );
};

export default withRouter(Reponse);
