import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { FirebaseContext } from './Firebase';
import Navigation from "./Navigation";

const Login = (props) => {

//=========================================================
//  Data
//=========================================================

    const firebase = useContext(FirebaseContext);

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [btn, setBtn] = useState(false);
    const [error, setError] = useState('');

//=========================================================
//  Fonctions
//=========================================================

    useEffect(() => {
        if (password.length > 5 && email !== '') {
            setBtn(true)
        } else if (btn) {
            setBtn(false)
        }
    }, [password, email, btn])

    const handleSubmit = e => {
        e.preventDefault();

        firebase.loginUser(email, password).then
        ((authUser) => {
            if (authUser === "Erreur: utilisateur inconnu ou mot de passe invalide")
            {
                alert("Erreur: utilisateur inconnu ou mot de passe invalide");
            }

            firebase.checkEmail(email).then
            (() => {

                // Effacer email et password
                setEmail('');
                setPassword('');
                setError('');

                props.history.push('/')
            })
        })
        
    }
    const tailleEcran = () => {
        return (window.innerWidth > 576 ? true : false )
      }
  
  
//=========================================================

    return (
        <div className="connexion">
            <Navigation />
            {tailleEcran() &&
            <div className="signUpLoginBox">
                <div className="slContainer">
                    <div className="formBoxLeftLogin"></div>
                        <div className="formBoxRight">
                            <div className="formContent">

                                {error !== '' && <span>{error.message}</span>}

                                <h1>Connexion</h1>
                                <form onSubmit={handleSubmit}>

                                    <div className="inputBox">
                                        <input onChange={e => setEmail(e.target.value)} value={email} type="email" autoComplete="off" required />
                                        <label htmlFor="email">Email</label>
                                    </div>

                                    <div className="inputBox">
                                        <input onChange={e => setPassword(e.target.value)} value={password} type="password" autoComplete="off" required />
                                        <label htmlFor="password">Mot de passe</label>
                                    </div>

                                    {btn ? <button>Connexion</button> : <button disabled>Connexion</button>}

                                </form>
                                <div className="linkContainer">
                                    <Link className="simpleLink" to="/signup">Pas encore inscrit? Inscrivez-vous maintenant.</Link>
                                    <br />
                                    <Link className="simpleLink" to="/forgetpassword">Mot de passe oublié? Récupérez-le ici.</Link>
                                </div>
                            </div>
                    </div>
                </div>
            </div>
            }
            {!tailleEcran() &&
            <div className="signUpLoginBox2">
                <div className="slContainer">
                        <div className="formBoxRight">
                            <div className="formContent">

                                {error !== '' && <span>{error.message}</span>}

                                <h1>Connexion</h1>
                                <form onSubmit={handleSubmit}>

                                    <div className="inputBox2">
                                        <input onChange={e => setEmail(e.target.value)} value={email} type="email" autoComplete="off" required />
                                        <label htmlFor="email">Email</label>
                                    </div>

                                    <div className="inputBox2">
                                        <input onChange={e => setPassword(e.target.value)} value={password} type="password" autoComplete="off" required />
                                        <label htmlFor="password">Mot de passe</label>
                                    </div>

                                    {btn ? <button>Connexion</button> : <button disabled>Connexion</button>}

                                </form>
                                <div className="linkContainer">
                                    <Link className="simpleLink" to="/signup">Pas encore inscrit? Inscrivez-vous maintenant.</Link>
                                    <br />
                                    <Link className="simpleLink" to="/forgetpassword">Mot de passe oublié? Récupérez-le ici.</Link>
                                </div>
                            </div>
                    </div>
                </div>
            </div>
            }
        </div>
    )
}

export default Login
