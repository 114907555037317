import React, { useState, useEffect } from "react";
import Navigation from "../components/Navigation";

const Mentions = (props) => {

//=========================================================
//  Détection 'escape' et traitement 
//=========================================================

  const [isEscape, setIsEscape] = useState(false);

  useEffect(() => {
      const handleEsc = (event) => {
         if (event.keyCode === 27) {
          setIsEscape(true);
        }
      };
      window.addEventListener('keydown', handleEsc);
    
      return () => {
        window.removeEventListener('keydown', handleEsc);
      };
    }, []);
    
    const backModule = () => {
      setTimeout(() => {
        props.history.push("/");
      }, 100);
    }
  
    const tailleEcran = () => {
      return (window.innerWidth > 576 ? true : false )
    }

//=========================================================
    
    return (
        <div className="home">
            <Navigation />
            {isEscape && backModule()}
            {tailleEcran() &&
            <div className="container-fluid">
                <div className="row pageTexte">
                    <div className="col-2"></div>
                    <div className="col-8">
                        <h1>MENTIONS LEGALES</h1>
                        <br />
                    </div>
                </div>
                <div className="row">
                    <div className="col-1"></div>
                    <div className="col-8">
                        <div className="pageMentions">
                            <h3>RAISON SOCIALE</h3><br />
                            <h5>AZTechInnov</h5><br />
                            53, rue Girardot <br />
                            93170 Bagnolet <br />
                            <br />
                            Tél: +(33) 06 66 64 40 56 <br />
                            email: aztechinnov@free.fr <br />
                        </div>
                    </div>
                </div>
            </div>
            }
            {!tailleEcran() &&
            <div className="container-fluid">
                <div className="row pageTexte">
                    <div className="col-12">
                        <h1>MENTIONS LEGALES</h1>
                        <br />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="pageMentions">
                            <h3>RAISON SOCIALE</h3><br />
                            <h5>AZTechInnov</h5><br />
                            53, rue Girardot <br />
                            93170 Bagnolet <br />
                            <br />
                            Tél: +(33) 06 66 64 40 56 <br />
                            email: aztechinnov@free.fr <br />
                        </div>
                    </div>
                </div>
            </div>
            }
        </div>
    )
};

export default Mentions;