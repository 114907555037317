import React, { useState, useEffect } from "react";
import Navigation from "../components/Navigation";
import audit from "../assets/audit2.jpg";

const Audit = (props) => {

//=========================================================
//  Détection 'escape' et traitement 
//=========================================================

  const [isEscape, setIsEscape] = useState(false);

  useEffect(() => {
    const handleEsc = (event) => {
       if (event.keyCode === 27) {
        setIsEscape(true);
      }
    };
    window.addEventListener('keydown', handleEsc);
  
    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, []);
  
  const backModule = () => {
    setTimeout(() => {
      props.history.push("/");
    }, 100);
  }

  const tailleEcran = () => {
    return (window.innerWidth > 576 ? true : false )
}

//=========================================================

  return (
    <div className="home">
      <Navigation />
      {isEscape && backModule()}
      {tailleEcran() &&
      <div className="container-fluid">
        <div className="row pageTexte">
          <div className="col-2"></div>
          <div className="col-8">
            <h1>AUDIT DE PROJET</h1>
          </div>
        </div>
        <div className="row pageItem">
          <div className="col-2"></div>
          <div className="col-7">
            <table className="container">
              <tbody>
                <tr>
                  <td className="imageBblock">
                    <img className="imageDev" src={audit} alt="audit" />
                  </td>
                  <td>
                    <p className="textTitre">Audit</p>
                    <p className="retraitTexte">
                      • état des lieux et analyse de la situation<br />
                      • interview des acteurs principaux: chef de projet, team leaders, experts techniques<br />
                      • analyse des écarts et établissement des tableaux des risques et des actions<br />
                      • propositions de corrections et d'améliorations<br />
                      • rapport d'audit<br />
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      }
      {!tailleEcran() &&
      <div className="container-fluid">
        <div className="row pageTexte">
          <div className="col-12">
            <h1>AUDIT DE PROJET</h1>
          </div>
        </div>
        <div className="row pageItem2">
          <div className="col-12">
            <table className="container2">
              <tbody>
                <tr>
                  <td className="imageBblock">
                    <img className="imageDev" src={audit} alt="audit" />
                  </td>
                  <td>
                    <p className="textTitre">Audit</p>
                    <p className="retraitTexte2">
                      • état des lieux et analyse de la situation<br />
                      • interview des acteurs principaux: chef de projet, team leaders, experts techniques<br />
                      • analyse des écarts et établissement des tableaux des risques et des actions<br />
                      • propositions de corrections et d'améliorations<br />
                      • rapport d'audit<br />
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      }
    </div>
  );
};

export default Audit;
