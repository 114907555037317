import React, { useState, useEffect, useContext } from 'react';
import Navigation from "../components/Navigation";
import { FirebaseContext } from '../components/Firebase';
import { withRouter } from "react-router";

const ClientsCreate = (props) => {

//=========================================================
//  Data
//=========================================================

    const firebase = useContext(FirebaseContext);

    const [firstname, setFirstname] = useState('');
    const [name, setName] = useState('');
    const [societe, setSociete] = useState('');
    const [adresse1, setAdresse1] = useState('');
    const [adresse2, setAdresse2] = useState('');
    const [codepostal, setCodepostal] = useState('');
    const [ville, setVille] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [typeUser, setTypeUser] = useState('user');
    const [isEscape, setIsEscape] = useState(false);

//=========================================================
//  Détection 'escape' et traitement 
//=========================================================

    useEffect(() => {
        const handleEsc = (event) => {
          if (event.keyCode === 27) {
            setIsEscape(true);
          }
        };
        window.addEventListener('keydown', handleEsc);
    
        return () => {
          window.removeEventListener('keydown', handleEsc);
        };
      }, []);
    
      const backModule = () => {
        setTimeout(() => {
            props.history.push("/clients");
        }, 100);
      }

//=========================================================
//  Fonctions
//=========================================================

    const isEmail = () => {
        let mail = document.getElementById('not-mail');
        let regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

        if (email.match(regex)) {
            mail.style.display = 'none';
            return true;
        }
        else {
            mail.style.display = 'block';
            mail.style.animation = 'dongle 1s';
            setTimeout(() => {
                mail.style.animation = 'none';
            }, 1000);
            return false;
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (isEmail() && firstname && name) {
            firebase.createUser(firstname, name, societe, phone, email, typeUser, adresse1, adresse2, codepostal, ville).then
                (() => {
                    alert("l'utilisateur a été créé");
                    props.history.push("/clients");
                })
        }
    }

    const handleCancel = (e) => {
        e.preventDefault();
        props.history.push("/clients");
    }

    const envoiButton = firstname === '' || name === '' || email === ''
        ? <input className="no-button tiers" type="button" value="Créer" onClick={handleSubmit} disabled />
        : <input className="button tiers" type="button" value="Créer" onClick={handleSubmit} />

//=========================================================

    return (
        <div className="client">
            <Navigation />
            {isEscape && backModule()}
            <div className="container-fluid">
                <div className="row pageTexte">
                    <div className="col-1"></div>
                    <div className="col-8">
                        <h1>CREATION D'UN UTILISATEUR</h1>
                        <form className="contact-form pageItem" onSubmit={handleSubmit}>
                            <div className="form-content">
                                <table>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <input
                                                    className="nameCLength"
                                                    type="text"
                                                    id="firstname"
                                                    name="firstname"
                                                    onChange={(e) => setFirstname(e.target.value)}
                                                    placeholder="prénom *"
                                                    value={firstname}
                                                    autoComplete="off"
                                                />
                                            </td>
                                            <td>
                                                <input
                                                    className="nameCLength"
                                                    type="text"
                                                    id="name"
                                                    name="name"
                                                    onChange={(e) => setName(e.target.value)}
                                                    placeholder="nom *"
                                                    value={name}
                                                    autoComplete="off"
                                                />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <input
                                    type="text"
                                    id="societe"
                                    name="societe"
                                    onChange={(e) => setSociete(e.target.value)}
                                    placeholder="société"
                                    value={societe}
                                />
                                <input
                                    type="text"
                                    id="adresse1"
                                    name="adresse1"
                                    onChange={(e) => setAdresse1(e.target.value)}
                                    placeholder="adresse1"
                                    value={adresse1}
                                />
                                <input
                                    type="text"
                                    id="adresse2"
                                    name="adresse2"
                                    onChange={(e) => setAdresse2(e.target.value)}
                                    placeholder="adresse2"
                                    value={adresse2}
                                />
                                <table>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <input
                                                    className="villeLength"
                                                    type="text"
                                                    id="codepostal"
                                                    name="codepostal"
                                                    onChange={(e) => setCodepostal(e.target.value)}
                                                    placeholder="code postal"
                                                    value={codepostal}
                                                />
                                            </td>
                                            <td>
                                                <input
                                                    className="villeLength"
                                                    type="text"
                                                    id="ville"
                                                    name="ville"
                                                    onChange={(e) => setVille(e.target.value)}
                                                    placeholder="ville"
                                                    value={ville}
                                                />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <input
                                    type="text"
                                    id="phone"
                                    name="phone"
                                    onChange={(e) => setPhone(e.target.value)}
                                    placeholder="téléphone"
                                    value={phone}
                                />
                                <div className="email-content">
                                    <label id="not-mail">Email non valide</label>
                                    <input
                                        type="email"
                                        id="email"
                                        name="email"
                                        onChange={(e) => setEmail(e.target.value)}
                                        placeholder="email *"
                                        value={email}
                                        autoComplete="off"
                                    />
                                </div>
                                <div>
                                    <label className="type-content">Type:</label>
                                    <select value={typeUser} onChange={(e) => setTypeUser(e.target.value)} >
                                        <option>admin</option>
                                        <option>user</option>
                                    </select>
                                </div>
                            </div>
                            <table>
                                <tbody>
                                    <tr>
                                        <td>
                                            {envoiButton}
                                        </td>
                                        <td>
                                            <input className="button deuxtiers" type="button" value="Annuler" onClick={handleCancel} />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </form>
                    </div>
                </div>
            </div>
        </div >
    );
};

export default withRouter(ClientsCreate);