import React, { useState, useContext } from 'react'
import { Link } from 'react-router-dom'
import { FirebaseContext } from './Firebase/';
import Navigation from "./Navigation";

const ForgetPassword = props => {

//=========================================================
//  Data
//=========================================================

    const firebase = useContext(FirebaseContext);

    const [email, setEmail] = useState("");
    const [success, setSuccess] = useState(null);
    const [error, setError] = useState(null);

//=========================================================
//  Fonctions
//=========================================================

    const handleSubmit = e => {
        e.preventDefault();

        const isAdmin = firebase.ckeckUserByEmail(email);
        if (!isAdmin) {

            firebase.passwordReset(email)
                .then(() => {
                    setError('');
                    setSuccess(`Consultez votre email ${email} pour changer le mot de passe`);
                    setEmail("");

                    setTimeout(() => {
                    }, 5000)
                })
                .catch(error => {
                    setError(error);
                    setEmail("");

                    setTimeout(() => {
                        props.history.push('/')
                    }, 2000)

                })

        } else {
            alert("Vous ne pouvez modifier le mot de passe d'un administrateur");
            setEmail("");
        }

    }

    const disabled = email === "";

    const tailleEcran = () => {
        return (window.innerWidth > 576 ? true : false )
      }
  
  
//=========================================================

    return (
        <div className="connexion">
            <Navigation />
            {tailleEcran() &&
            <div className="signUpLoginBox">
                <div className="slContainer">
                <div className="formBoxLeftForget"></div>
                    <div className="formBoxRight">
                        <div className="formContent">

                            { 
                                success && <span 
                                    style={{ 
                                    border: "1px solid green",
                                    background: "green",
                                    color: "#ffffff"
                                }}
                                >
                                    {success}
                                </span>
                            }

                            {error && <span>{error.message}</span>}

                            <h1>Mot de passe oublié?</h1>
                            <form onSubmit={handleSubmit}>

                                <div className="inputBox">
                                    <input onChange={e => setEmail(e.target.value)} value={email} type="email" autoComplete="off" required />
                                    <label htmlFor="email">Email</label>
                                </div>

                                <button disabled={disabled}>Récupérer</button>

                            </form>

                            <div className="linkContainer">
                                <Link className="simpleLink" to="/login">Déjà inscrit? Connectez-vous.</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            }
            {!tailleEcran() &&
            <div className="signUpLoginBox2">
                <div className="slContainer">
                    <div className="formBoxRight">
                        <div className="formContent">

                            { 
                                success && <span 
                                    style={{ 
                                    border: "1px solid green",
                                    background: "green",
                                    color: "#ffffff"
                                }}
                                >
                                    {success}
                                </span>
                            }

                            {error && <span>{error.message}</span>}

                            <h1>Mot de passe oublié?</h1>
                            <form onSubmit={handleSubmit}>

                                <div className="inputBox2">
                                    <input onChange={e => setEmail(e.target.value)} value={email} type="email" autoComplete="off" required />
                                    <label htmlFor="email">Email</label>
                                </div>

                                <button disabled={disabled}>Récupérer</button>

                            </form>

                            <div className="linkContainer">
                                <Link className="simpleLink" to="/login">Déjà inscrit? Connectez-vous.</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            }
        </div>
    )
}

export default ForgetPassword
