import React, { useState, useEffect, useContext } from 'react'
import { FirebaseContext } from './Firebase'
import ReactToolTip from 'react-tooltip'
import Navigation from "./Navigation";

const Logout = (props) => {

//=========================================================
//  Data
//=========================================================

    const firebase = useContext(FirebaseContext);

    const [checked, setChecked] = useState(false);

//=========================================================
//  Fonctions
//=========================================================

    useEffect(() => {
        if (checked) {
            //console.log("Déconnexion");
            firebase.signoutUser();
        }

    }, [checked, firebase]);

    const handleChange = event => {
        setChecked(event.target.checked);
        setTimeout(() => {
            props.history.push('/')
        }, 500)
    }

//=========================================================

    return (
        <div className="connexion">
            <Navigation />
            <div className="logoutContainer">
                <label className="switch">
                    <input
                        onChange={handleChange}
                        type="checkbox"
                        checked={checked}
                    />
                    <span className="slider round" data-tip="Déconnexion"></span>
                </label>
                <ReactToolTip
                    place="left"
                    effect="solid"
                />
            </div>
        </div>
    )
}

export default Logout
