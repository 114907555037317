import React from "react";
import { NavLink } from "react-router-dom";
import Navigation from "../components/Navigation";
import dev from "../assets/developpement.jpg";
import prj from "../assets/gestiondeprojet.jpg";
import aud from "../assets/audit.jpg";
import res from "../assets/reseau.jpg";

//=========================================================
// style image de fond
//=========================================================

const styleMain = {
  position: 'absolute',
  width: '100%',
  height: '82vh',
  left: '0vw',
  alignItems: 'center',
  justifyContent: 'center',
  opacity: '75%'
}

const styleMain2 = {
  position: 'absolute',
  width: '100%',
  height: '75vh',
  left: '0vw',
  alignItems: 'center',
  justifyContent: 'center',
  opacity: '75%'
}

const tailleEcran = () => {
  return (window.innerWidth > 576 ? true : false )
}

//=========================================================

const Home = () => {

  return (
    <div className="home">
      { tailleEcran() &&
      <div>
        <Navigation />
        <div className="row">
          <div className="col-12">
            <img src="./img/main1.jpg" style={styleMain} alt="main" />
          </div>
          <div className="container-fluid">
            <div className="row">
              <div className="col-2">
                <label className="pageTitre">Conseil et ingénierie en hautes technologies</label>
              </div>
            </div>
            <div className="row page">
              <div className="col-2"></div>
              <div className="col-2">
                <NavLink exact to="/developpement">
                  <img className="imageItem" src={dev} alt="logo" />
                </NavLink>
                <p className="textItem">Développement</p>
              </div>
              <div className="col-2">
                <NavLink exact to="/gestiondeprojet">
                  <img className="imageItem" src={prj} alt="logo" />
                </NavLink>
                <p className="textItem">Gestion de projet</p>
              </div>
              <div className="col-2">
                <NavLink exact to="/audit">
                  <img className="imageItem" src={aud} alt="logo" />
                </NavLink>
                <p className="textItem">Audit</p>
              </div>
              <div className="col-2">
                <NavLink exact to="/reseau">
                  <img className="imageItem" src={res} alt="logo" />
                </NavLink>
                <p className="textItem">Bureautique et réseau</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      }

      { !tailleEcran() &&
      <div>
        <Navigation />
        <div className="row">
          <div className="col-12">
            <img src="./img/main1.jpg" style={styleMain2} alt="main" />
          </div>
          <div className="container-fluid">
            <div className="row">
              <div className="col-2">
                <label className="pageTitre2">Conseil et ingénierie en hautes technologies</label>
              </div>
            </div>
            <div className="row page">
              <div className="col-3">
                <NavLink exact to="/developpement">
                  <img className="imageItem2" src={dev} alt="logo" />
                </NavLink>
                <p className="textItem2">Développement</p>
              </div>
              <div className="col-3">
                <NavLink exact to="/gestiondeprojet">
                  <img className="imageItem2" src={prj} alt="logo" />
                </NavLink>
                <p className="textItem2">Gestion de projet</p>
              </div>
              <div className="col-3">
                <NavLink exact to="/audit">
                  <img className="imageItem2" src={aud} alt="logo" />
                </NavLink>
                <p className="textItem2">Audit</p>
              </div>
              <div className="col-3">
                <NavLink exact to="/reseau">
                  <img className="imageItem2" src={res} alt="logo" />
                </NavLink>
                <p className="textItem2">Bureautique</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      }
    </div>
  );
};

export default Home;
