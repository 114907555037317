import React, { useState, useEffect } from "react";
import Navigation from "../components/Navigation";
import proj from "../assets/gestiondeprojet2.jpg";
import formation from "../assets/formation.jpg";

const Gestiondeprojet = (props) => {

//=========================================================
//  Détection 'escape' et traitement 
//=========================================================

  const [isEscape, setIsEscape] = useState(false);

  useEffect(() => {
    const handleEsc = (event) => {
      if (event.keyCode === 27) {
        setIsEscape(true);
      }
    };
    window.addEventListener('keydown', handleEsc);

    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, []);

  const backModule = () => {
    setTimeout(() => {
      props.history.push("/");
    }, 100);
  }

  const tailleEcran = () => {
    return (window.innerWidth > 576 ? true : false )
}

//=========================================================
  
  return (
    <div className="home">
      <Navigation />
      {isEscape && backModule()}
      {tailleEcran() &&
      <div className="container-fluid">
        <div className="row pageTexte">
          <div className="col-2"></div>
          <div className="col-8">
            <h1>GESTION DE PROJET</h1>
          </div>
        </div>
        <div className="row pageItem">
          <div className="col-2"></div>
          <div className="col-7">
            <table className="container">
              <tbody>
                <tr>
                  <td className="imageBlock">
                    <img className="imageDev" src={proj} alt="proj" />
                  </td>
                  <td>
                    <p className="textTitre">Gestion de projet</p>
                    <p className="retraitTexte">
                      • analyse des besoins du projet<br />
                      • composition des équipes projet<br />
                      • définition des livrables du projet<br />
                      • établissement des éléments de contrôle: comités de directions, <br />
                      réunions techniques, comptes rendus de réunions, audits techniques<br />
                      • création du tableau des risques et de suivi des actions<br />
                      • création du plan projet<br />
                      • suivi du plan projet<br />
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
            <br />
            <table className="container">
              <tbody>
                <tr>
                  <td className="imageBlock">
                    <img className="imageDev" src={formation} alt="formation" />
                  </td>
                  <td>
                    <p className="textTitre">Formations</p>
                    <p className="retraitTexte">
                      • formation à la gestion de projet<br />
                      • formation à la gestion d'équipe<br />
                      • formation au leadership<br />
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      }
      {!tailleEcran() &&
      <div className="container-fluid">
        <div className="row pageTexte">
          <div className="col-12">
            <h1>GESTION DE PROJET</h1>
          </div>
        </div>
        <div className="row pageItem2">
          <div className="col-12">
            <table className="container2">
              <tbody>
                <tr>
                  <td className="imageBlock">
                    <img className="imageDev" src={proj} alt="proj" />
                  </td>
                  <td>
                    <p className="textTitre">Gestion de projet</p>
                    <p className="retraitTexte2">
                      • analyse des besoins du projet<br />
                      • composition des équipes projet<br />
                      • définition des livrables du projet<br />
                      • établissement des éléments de contrôle: comités de directions, <br />
                      réunions techniques, comptes rendus de réunions, audits techniques<br />
                      • création du tableau des risques et de suivi des actions<br />
                      • création du plan projet<br />
                      • suivi du plan projet<br />
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
            <br />
            <table className="container2">
              <tbody>
                <tr>
                  <td className="imageBlock">
                    <img className="imageDev" src={formation} alt="formation" />
                  </td>
                  <td>
                    <p className="textTitre">Formations</p>
                    <p className="retraitTexte2">
                      • formation à la gestion de projet<br />
                      • formation à la gestion d'équipe<br />
                      • formation au leadership<br />
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      }
    </div>
  );
};

export default Gestiondeprojet;
