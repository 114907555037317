import React, { useState, useEffect, useContext } from "react";
import Navigation from "../components/Navigation";
import { FirebaseContext } from '../components/Firebase';
import emailjs from 'emailjs-com';

const Contact = (props) => {

//=========================================================
//  Data
//=========================================================

  const firebase = useContext(FirebaseContext);

  const [firstname, setFirstname] = useState("");
  const [name, setName] = useState("");
  const [company, setCompany] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [objet, setObjet] = useState("");
  const [message, setMessage] = useState("");
  const [isEscape, setIsEscape] = useState(false);
  const [isGetValues, setIsGetValues] = useState(false);

//===========================================================
//   Data de connexion à EmailJS
//===========================================================
  
  const service_EmailJS = "service_g365wcp";
  const template_EmailJS = "template_80k4bhu";
  const user_EmailJS = "user_r28zznke6kimtGSuu2zAa";

//=========================================================
//  Détection 'escape' et traitement 
//=========================================================

  useEffect(() => {
    const handleEsc = (event) => {
      if (event.keyCode === 27) {
        setIsEscape(true);
      }
    };
    window.addEventListener('keydown', handleEsc);

    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, []);

  const backModule = () => {
    setTimeout(() => {
      props.history.push("/");
    }, 100);
  }

//=========================================================
//  Fonctions
//=========================================================

  const getValues = () => {
    setIsGetValues(true);
    const emailUser = firebase.getUserEmail();
    if (emailUser !== '') {
      firebase.checkUserByEmail(emailUser).then
        ((userData) => {
          setFirstname(userData.prenom);
          setName(userData.nom);
          setCompany(userData.societe);
          setPhone(userData.tel);
          setEmail(userData.email);
        })
    }
  }

  const createMessage = (email, objet, message) => {

    let currentDate = new Date().toLocaleString() + "";
    let univDate = currentDate.substring(6,10)+'/'+currentDate.substring(3,5)+'/'+currentDate.substring(0,2)+currentDate.substring(10);
    firebase.addMessage(email, univDate, currentDate, objet, message);

  }

  const isEmail = () => {
    console.log("email");
    let mail = document.getElementById('not-mail');
    let regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

    if (email.match(regex)) {
      mail.style.display = 'none';
      return true;
    }
    else {
      console.log("email nok");
      mail.style.display = 'block';
      mail.style.animation = 'dongle 1s';
      setTimeout(() => {
        mail.style.animation = 'none';
      }, 1000);
      return false;
    }
  }

  const failMessage = (message) => {
    let formMess = document.querySelector('.form-message');

    formMess.innerHTML = message;
    formMess.style.opacity = 1;
    formMess.style.background = "#FD5757";
    formMess.style.textAlign = "center";

    document.getElementById('firstname').classList.add('error');
    document.getElementById('name').classList.add('error');
    document.getElementById('email').classList.add('error');
    document.getElementById('message').classList.add('error');
  }

  const successMessage = () => {
    let formMess = document.querySelector('.form-message');

    formMess.innerHTML = 'Message envoyé! Nous vous recontacterons dès que possible';
    formMess.style.opacity = 1;
    formMess.style.background = '#00c1ec';
    formMess.style.textAlign = "center";

    document.getElementById('firstname').classList.remove('error');
    document.getElementById('email').classList.remove('error');
    document.getElementById('message').classList.remove('error');
    document.getElementById('name').classList.remove('error');

    setTimeout(() => {
      formMess.style.opacity = 0;
      props.history.push('/')
    }, 5000);
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    if (isEmail() && firstname && name && message) {

      firebase.createUser(firstname, name, company, phone, email, 'user', '', '', '', '');
      createMessage(email, objet, message);

      let templateParams = {
        firstname: firstname,
        name: name,
        company: company,
        phone: phone,
        email: email,
        objet: objet,
        message: message
      };

      emailjs.send(service_EmailJS, template_EmailJS, templateParams, user_EmailJS)
        .then(function (response) {
          setFirstname('');
          setName('');
          setCompany('');
          setPhone('');
          setEmail('');
          setObjet('');
          setMessage('');
          successMessage();
        }, function (error) {
          failMessage("Une erreur s'est produite, veuillez réessayer");
          props.history.push('/')
        });
    }
    else {
      failMessage('Merci de remplir correctement les champs requis *')
      props.history.push('/')
    }

  }

  const envoiButton = firstname === '' || name === '' || email === '' || objet === '' || message === ''
    ? <input className="no-button" type="button" value="Envoyer" onClick={handleSubmit} disabled />
    : <input className="button" type="button" value="Envoyer" onClick={handleSubmit} />

    const tailleEcran = () => {
      return (window.innerWidth > 576 ? true : false )
  }
  
//===========================================================

  return (
    <div className="contact">
      <Navigation />
      {isEscape && backModule()}
      {!isGetValues && getValues()}
      {tailleEcran() &&
      <div>
        <form className="contact-form pageItem" onSubmit={handleSubmit}>
          <h2>Contactez-nous</h2>
          <div className="form-content">
            <input
              type="text"
              id="firstname"
              name="firstname"
              onChange={(e) => setFirstname(e.target.value)}
              placeholder="prénom *"
              value={firstname}
              autoComplete="off"
            />
            <input
              type="text"
              id="name"
              name="name"
              onChange={(e) => setName(e.target.value)}
              placeholder="nom *"
              value={name}
              autoComplete="off"
            />
            <input
              type="text"
              id="company"
              name="company"
              onChange={(e) => setCompany(e.target.value)}
              placeholder="société"
              value={company}
            />
            <input
              type="text"
              id="phone"
              name="phone"
              onChange={(e) => setPhone(e.target.value)}
              placeholder="téléphone"
              value={phone}
            />
            <div className="email-content">
              <label id="not-mail">Email non valide</label>
              <input
                type="email"
                id="email"
                name="email"
                onChange={(e) => setEmail(e.target.value)}
                placeholder="email *"
                value={email}
                autoComplete="off"
              />
              <input
                type="text"
                id="objet"
                name="objet"
                onChange={(e) => setObjet(e.target.value)}
                placeholder="objet *"
                value={objet}
              />
            </div>
            <textarea
              id="message"
              name="message"
              onChange={(e) => setMessage(e.target.value)}
              placeholder="message (limité à 1000 car.) *"
              value={message}
              maxLength="1000"
            />
          </div>
          {envoiButton}
          <div className="form-message"></div>
        </form>
      </div>
      }
      {!tailleEcran() &&
      <div>
        <form className="contact-form2 pageItem2" onSubmit={handleSubmit}>
          <h2>Contactez-nous</h2>
          <div className="form-content">
            <input
              type="text"
              id="firstname"
              name="firstname"
              onChange={(e) => setFirstname(e.target.value)}
              placeholder="prénom *"
              value={firstname}
              autoComplete="off"
            />
            <input
              type="text"
              id="name"
              name="name"
              onChange={(e) => setName(e.target.value)}
              placeholder="nom *"
              value={name}
              autoComplete="off"
            />
            <input
              type="text"
              id="company"
              name="company"
              onChange={(e) => setCompany(e.target.value)}
              placeholder="société"
              value={company}
            />
            <input
              type="text"
              id="phone"
              name="phone"
              onChange={(e) => setPhone(e.target.value)}
              placeholder="téléphone"
              value={phone}
            />
            <div className="email-content">
              <label id="not-mail">Email non valide</label>
              <input
                type="email"
                id="email"
                name="email"
                onChange={(e) => setEmail(e.target.value)}
                placeholder="email *"
                value={email}
                autoComplete="off"
              />
              <input
                type="text"
                id="objet"
                name="objet"
                onChange={(e) => setObjet(e.target.value)}
                placeholder="objet *"
                value={objet}
              />
            </div>
            <textarea
              id="message"
              name="message"
              onChange={(e) => setMessage(e.target.value)}
              placeholder="message (limité à 1000 car.) *"
              value={message}
              maxLength="1000"
            />
          </div>
          {envoiButton}
          <div className="form-message"></div>
        </form>
      </div>
      }
    </div>
  );
};

export default Contact;
