import React, { useState, useEffect } from "react";
import Navigation from "../components/Navigation";

const Cgu = (props) => {

//=========================================================
//  Détection 'escape' et traitement 
//=========================================================

    const [isEscape, setIsEscape] = useState(false);

    useEffect(() => {
      const handleEsc = (event) => {
         if (event.keyCode === 27) {
          setIsEscape(true);
        }
      };
      window.addEventListener('keydown', handleEsc);
    
      return () => {
        window.removeEventListener('keydown', handleEsc);
      };
    }, []);
    
    const backModule = () => {
      setTimeout(() => {
        props.history.push("/");
      }, 100);
    }

    const tailleEcran = () => {
      return (window.innerWidth > 576 ? true : false )
    }

//=========================================================
    
    return (
        <div className="home">
            <Navigation />
            {isEscape && backModule()}
            {tailleEcran() &&
            <div className="container-fluid">
                <div className="row pageTexte">
                    <div className="col-2"></div>
                    <div className="col-8">
                        <h1>CONDITIONS GENERALES D'UTILISATION</h1>
                        <br />
                    </div>
                </div>
                <div className="row">
                    <div className="col-1"></div>
                    <div className="col-7">

<div className="pageCGU">
<h3>Préambule</h3>
Les présentes conditions générales d'utilisation sont conclues entre :<br />
•	Le gérant du site internet, ci-après désigné « l'Éditeur », <br />
•	Toute personne souhaitant accéder au site et à ses services, ci-après appelé « l’Utilisateur ».<br />
<br />
<h3>Principes</h3>
Les présentes conditions générales d'utilisation ont pour objet l'encadrement juridique de l'utilisation du site « AZTechInnov » et de ses services.
<br />
Le site Internet « AZTechInnov » est un service de :<br />
La société AZTECHINNOV située 53 rue Girardot, 93170, Bagnolet, France <br />
•	Adresse URL du site : https://aztinnov.com <br />
•	Email : aztechinnov@free.fr <br />
•	Téléphone : 33 (0) 6 66 64 40 56 <br />

Les conditions générales d'utilisation doivent être acceptées par tout Utilisateur, et son accès au site vaut acceptation de ces conditions. <br />
<br />
<h3>Evolution et durée des CGU</h3>
Les présentes conditions générales d'utilisation sont conclues pour une durée indéterminée. Le contrat produit ses effets à l'égard de l'Utilisateur à compter du début de 
l'utilisation du service.<br />
Le site « AZTechInnov » se réserve le droit de modifier les clauses de ces conditions générales d'utilisation à tout moment et sans justification.<br />
<br />
<h3>Accès au site</h3>
Tout Utilisateur ayant accès à internet peut accéder gratuitement et depuis n'importe où au site « AZTechInnov ». Les frais supportés par l'Utilisateur pour y accéder 
(connexion internet, matériel informatique, etc.) ne sont pas à la charge de l’Editeur. <br />
Le site et ses différents services peuvent être interrompus ou suspendus par l'Editeur, notamment à l'occasion d’une maintenance, sans obligation de préavis ou de justification.<br />
<br />
<h3>Responsabilités</h3>
La responsabilité de l'Éditeur ne peut être engagée en cas de défaillance, panne, difficulté ou interruption de fonctionnement, empêchant l'accès au site ou à une de ses 
fonctionnalités. <br />
Le matériel de connexion au site utilisé est sous l'entière responsabilité de l'Utilisateur qui doit prendre toutes les mesures appropriées pour protéger 
le matériel et les données notamment d'attaques virales par Internet. <br />
L'utilisateur est par ailleurs le seul responsable des sites et données qu'il consulte.<br />

L'Éditeur ne pourra être tenu responsable en cas de poursuites judiciaires à l'encontre de l'Utilisateur :<br />
•	Du fait de l'usage du site ou de tout service accessible via Internet ; <br />
•	Du fait du non-respect par l'Utilisateur des présentes conditions générales. <br />

L'Éditeur n'est pas responsable des dommages causés à l'Utilisateur, à des tiers et/ou à l'équipement de l’Utilisateur du fait de sa connexion ou de son utilisation du site 
et l'Utilisateur renonce à toute action contre l'Éditeur de ce fait. <br />

Si l'Éditeur venait à faire l'objet d'une procédure amiable ou judiciaire à raison de l'utilisation du site par l'Utilisateur, il pourrait se retourner contre lui pour 
obtenir indemnisation de tous les préjudices, sommes, condamnations et frais qui pourraient découler de cette procédure. <br />
<br />
<h3>Propriété intellectuelle</h3>
Tous les documents techniques, produits, photographies, textes, logos, dessins, vidéos, etc… sont soumis à des droits d'auteur et sont protégés par le Code de la propriété 
intellectuelle. Lorsqu'ils sont remis à nos clients, ils demeurent la propriété exclusive de l’Editeur, seul titulaire des droits de propriété intellectuelle 
sur ces documents, qui doivent lui être rendus à sa demande. <br />

Nos clients s'engagent à ne faire aucun usage de ces documents, susceptible de porter atteinte aux droits de propriété industrielle ou intellectuelle du fournisseur 
et s'engagent à ne les divulguer à aucun tiers, en dehors d'une autorisation expresse et préalable donnée par l'Editeur. <br />
<br />
<h3>Liens hypertextes</h3>
La mise en place par l’Utilisateur de tous liens hypertextes vers tout ou partie du site est strictement interdite, sauf autorisation préalable et écrite de l'Éditeur, 
sollicitée par courrier à l'adresse suivante : AZTECHINNOV, 53 rue Girardot, 93170, Bagnolet, France <br />

L'Éditeur est libre de refuser cette autorisation sans avoir à justifier de quelque manière que ce soit sa décision. Dans le cas où l'Éditeur accorderait son autorisation, 
celle-ci n'est dans tous les cas que temporaire et pourra être retirée à tout moment, sans obligation de justification à la charge de l'Éditeur. <br />
Dans tous les cas, tout lien devra être retiré sur simple demande de l'Éditeur. <br />
Toute information accessible via un lien vers d'autres sites n'est pas sous le contrôle de l'Éditeur qui décline toute responsabilité quant à leur contenu. <br />
<br />
<h3>Protection des données personnelles</h3>
Données collectées<br />
Les données à caractère personnel qui sont collectées sur ce site sont les suivantes :<br />
•	Formulaire de contact : prénom, nom, adresse, téléphone et adresse email<br />
•	Connexion : lors de la connexion de l'utilisateur au site web, les données de connexion sont enregistrées sur le site. <br />
Les données sont sauvegardées sur le site pendant 2 ans<br />
<br />
<h3>Cookies</h3>
Le site « AZTechInnov » n’utilise pas de cookies.<br />
<br />
<h3>Loi applicable</h3>
Les présentes conditions générales d'utilisation sont soumises à l'application du droit français.<br />

Si les parties n'arrivent pas à résoudre un litige à l'amiable, le litige sera soumis à la compétence des tribunaux français.<br/>
<br />
</div>

                    </div>
                </div>
            </div>
            }
            {!tailleEcran() &&
            <div className="container-fluid">
                <div className="row pageTexte">
                    <div className="col-12">
                        <h1>CONDITIONS GENERALES D'UTILISATION</h1>
                        <br />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">

<div className="pageCGU2">
<h3>Préambule</h3>
Les présentes conditions générales d'utilisation sont conclues entre :<br />
•	Le gérant du site internet, ci-après désigné « l'Éditeur », <br />
•	Toute personne souhaitant accéder au site et à ses services, ci-après appelé « l’Utilisateur ».<br />
<br />
<h3>Principes</h3>
Les présentes conditions générales d'utilisation ont pour objet l'encadrement juridique de l'utilisation du site « AZTechInnov » et de ses services.
<br />
Le site Internet « AZTechInnov » est un service de :<br />
La société AZTECHINNOV située 53 rue Girardot, 93170, Bagnolet, France <br />
•	Adresse URL du site : https://aztinnov.com <br />
•	Email : aztechinnov@free.fr <br />
•	Téléphone : 33 (0) 6 66 64 40 56 <br />

Les conditions générales d'utilisation doivent être acceptées par tout Utilisateur, et son accès au site vaut acceptation de ces conditions. <br />
<br />
<h3>Evolution et durée des CGU</h3>
Les présentes conditions générales d'utilisation sont conclues pour une durée indéterminée. Le contrat produit ses effets à l'égard de l'Utilisateur à compter du début de 
l'utilisation du service.<br />
Le site « AZTechInnov » se réserve le droit de modifier les clauses de ces conditions générales d'utilisation à tout moment et sans justification.<br />
<br />
<h3>Accès au site</h3>
Tout Utilisateur ayant accès à internet peut accéder gratuitement et depuis n'importe où au site « AZTechInnov ». Les frais supportés par l'Utilisateur pour y accéder 
(connexion internet, matériel informatique, etc.) ne sont pas à la charge de l’Editeur. <br />
Le site et ses différents services peuvent être interrompus ou suspendus par l'Editeur, notamment à l'occasion d’une maintenance, sans obligation de préavis ou de justification.<br />
<br />
<h3>Responsabilités</h3>
La responsabilité de l'Éditeur ne peut être engagée en cas de défaillance, panne, difficulté ou interruption de fonctionnement, empêchant l'accès au site ou à une de ses 
fonctionnalités. <br />
Le matériel de connexion au site utilisé est sous l'entière responsabilité de l'Utilisateur qui doit prendre toutes les mesures appropriées pour protéger 
le matériel et les données notamment d'attaques virales par Internet. <br />
L'utilisateur est par ailleurs le seul responsable des sites et données qu'il consulte.<br />

L'Éditeur ne pourra être tenu responsable en cas de poursuites judiciaires à l'encontre de l'Utilisateur :<br />
•	Du fait de l'usage du site ou de tout service accessible via Internet ; <br />
•	Du fait du non-respect par l'Utilisateur des présentes conditions générales. <br />

L'Éditeur n'est pas responsable des dommages causés à l'Utilisateur, à des tiers et/ou à l'équipement de l’Utilisateur du fait de sa connexion ou de son utilisation du site 
et l'Utilisateur renonce à toute action contre l'Éditeur de ce fait. <br />

Si l'Éditeur venait à faire l'objet d'une procédure amiable ou judiciaire à raison de l'utilisation du site par l'Utilisateur, il pourrait se retourner contre lui pour 
obtenir indemnisation de tous les préjudices, sommes, condamnations et frais qui pourraient découler de cette procédure. <br />
<br />
<h3>Propriété intellectuelle</h3>
Tous les documents techniques, produits, photographies, textes, logos, dessins, vidéos, etc… sont soumis à des droits d'auteur et sont protégés par le Code de la propriété 
intellectuelle. Lorsqu'ils sont remis à nos clients, ils demeurent la propriété exclusive de l’Editeur, seul titulaire des droits de propriété intellectuelle 
sur ces documents, qui doivent lui être rendus à sa demande. <br />

Nos clients s'engagent à ne faire aucun usage de ces documents, susceptible de porter atteinte aux droits de propriété industrielle ou intellectuelle du fournisseur 
et s'engagent à ne les divulguer à aucun tiers, en dehors d'une autorisation expresse et préalable donnée par l'Editeur. <br />
<br />
<h3>Liens hypertextes</h3>
La mise en place par l’Utilisateur de tous liens hypertextes vers tout ou partie du site est strictement interdite, sauf autorisation préalable et écrite de l'Éditeur, 
sollicitée par courrier à l'adresse suivante : AZTECHINNOV, 53 rue Girardot, 93170, Bagnolet, France <br />

L'Éditeur est libre de refuser cette autorisation sans avoir à justifier de quelque manière que ce soit sa décision. Dans le cas où l'Éditeur accorderait son autorisation, 
celle-ci n'est dans tous les cas que temporaire et pourra être retirée à tout moment, sans obligation de justification à la charge de l'Éditeur. <br />
Dans tous les cas, tout lien devra être retiré sur simple demande de l'Éditeur. <br />
Toute information accessible via un lien vers d'autres sites n'est pas sous le contrôle de l'Éditeur qui décline toute responsabilité quant à leur contenu. <br />
<br />
<h3>Protection des données personnelles</h3>
Données collectées<br />
Les données à caractère personnel qui sont collectées sur ce site sont les suivantes :<br />
•	Formulaire de contact : prénom, nom, adresse, téléphone et adresse email<br />
•	Connexion : lors de la connexion de l'utilisateur au site web, les données de connexion sont enregistrées sur le site. <br />
Les données sont sauvegardées sur le site pendant 2 ans<br />
<br />
<h3>Cookies</h3>
Le site « AZTechInnov » n’utilise pas de cookies.<br />
<br />
<h3>Loi applicable</h3>
Les présentes conditions générales d'utilisation sont soumises à l'application du droit français.<br />

Si les parties n'arrivent pas à résoudre un litige à l'amiable, le litige sera soumis à la compétence des tribunaux français.<br/>
<br />
</div>

                    </div>
                </div>
            </div>
            }
        </div>
    )
};

export default Cgu;