import React, { useState, useEffect, useContext } from 'react';
import Navigation from "../components/Navigation";
import { FirebaseContext } from '../components/Firebase';
import corbeille from "../assets/delete1.png";
import document from "../assets/document.png";
import addrecord from "../assets/addrecord.png";

const Clients = (props) => {

//=========================================================
//  Data
//=========================================================

    const firebase = useContext(FirebaseContext);

    const [listeC, setListeC] = useState([]);
    const [nbUsers, setNbUsers] = useState(0);
    const [isMounted, setIsMounted] = useState(false);
    const [isEscape, setIsEscape] = useState(false);

    let inc = 0;

//=========================================================
//  Détection 'escape' et traitement 
//=========================================================

    useEffect(() => {
        const handleEsc = (event) => {
          if (event.keyCode === 27) {
            setIsEscape(true);
          }
        };
        window.addEventListener('keydown', handleEsc);
    
        return () => {
          window.removeEventListener('keydown', handleEsc);
        };
      }, []);
    
      const backModule = () => {
        setTimeout(() => {
            props.history.push("/");
        }, 100);
      }

//=========================================================
//  Fonctions
//=========================================================      

    async function handleCreateClient() {
        props.history.push('/clientsCreate');
    }

    async function handleUpdateClient(liste) {
        props.history.push('/clientsUpdate', { userData: liste });
    }

    async function handleDelete(liste, index) {
        const reponse = window.confirm("Voulez-vous vraiment supprimer ce client?");
        if (reponse) {
            firebase.deleteUser(liste.id).then
                (() => {
                    alert("l'utilisateur a été supprimé");
                    let listeDel = listeC;
                    listeDel.splice(index, 1);
                    setListeC(listeDel);
                    let nbr = nbUsers;
                    nbr--;
                    setNbUsers(nbr);

                    props.history.push("/clients");
                })
        }
    }

    async function handleMessages(liste) {
        props.history.push('/messages', {userData: liste.email});
    }

    async function composeClients() {
        setIsMounted(true);
        inc++;
        if (inc === 1) {
            const clients = await firebase.getUsers();

            let listeTemp = clients.docs;
            listeTemp = listeTemp.map(client => ({
                 id: client.id, prenom: client.data().prenom, nom: client.data().nom, societe: client.data().societe,
                tel: client.data().tel, email: client.data().email, adresse1: client.data().adresse1, adresse2: client.data().adresse2,
                codepostal: client.data().codepostal, ville: client.data().ville, typeUser: client.data().typeUser }));

            setNbUsers(clients.size);
            setListeC(listeTemp);
        }
    }

//=========================================================      

    return (
        <div className="home">
            <Navigation />
            {isEscape && backModule()}
            <div className="container-fluid">
                <div className="row pageTexte">
                    <div className="col-1"></div>
                    <div className="col-10">
                        <div>
                            <table>
                                <tbody>
                                    <tr>
                                        <td>
                                            <h1 className="modelTitre">LISTE DES UTILISATEURS</h1>
                                        </td>
                                        <td>
                                            <h4 className="modelEspaceMin"> </h4>
                                        </td>
                                        <td>
                                            <h4>Nb d'utilisateurs: {nbUsers}</h4>
                                        </td>
                                        <td>
                                            <img className="modelIconeAdd" type="button" onClick={() => handleCreateClient()} src={addrecord} alt="addrecord" />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-1"></div>
                    <div className="col-7">
                        <br />
                        <div className="pageClient">
                            {!isMounted && composeClients()}
                            {listeC.map((listC, id) =>
                                <div className="col-7 modelClient" key={id}>
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td className="modelData" onClick={() => handleUpdateClient(listC)} key={id}>
                                                    <label className="sizeLabel">Prénom:</label><label className="sizeLabel"></label><label>{listC.prenom}</label><br />
                                                    <label className="sizeLabel">Nom:</label><label className="sizeLabel"></label><label>{listC.nom}</label><br />
                                                    <label className="sizeLabel">Société:</label><label className="sizeLabel"></label><label>{listC.societe}</label><br />
                                                    <label className="sizeLabel">Téléphone:</label><label className="sizeLabel"></label><label>{listC.tel}</label><br />
                                                    <label className="sizeLabel">Email:</label><label className="sizeLabel"></label><label>{listC.email}</label><br />
                                                    <label className="sizeLabel"></label><br />
                                                </td>
                                                <td>
                                                    <img className="modelIcone" type="button" onClick={() => handleDelete(listC, id)} key={id} src={corbeille} alt="corbeille" />
                                                </td>
                                                <td>
                                                    <img className="modelIcone" type="button" onClick={() => handleMessages(listC)} key={id} src={document} alt="doc" />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Clients;