import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import Firebase, { FirebaseContext } from './components/Firebase'
import "./styles/index.scss";

// Pour utiliser Firebase, on crée le contexte Firebase avant d'appeler App 
// de manière à pouvoir l'utiliser dans toute l'appli react
ReactDOM.render(
  <React.StrictMode>
    <FirebaseContext.Provider value={new Firebase()}>
      <App />
    </FirebaseContext.Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
