import React, { useState, useEffect, useContext } from 'react';
import Navigation from "../components/Navigation";
import { FirebaseContext } from '../components/Firebase';

const Messages = (props) => {

//=========================================================
//  Data
//=========================================================
    
    const firebase = useContext(FirebaseContext);

    const [listeM, setListeM] = useState([]);
    const [eMail, setEMail] = useState('');
    const [isAdmin, setIsAdmin] = useState(false);
    const [isMounted, setIsMounted] = useState(false);
    const [nbMessages, setNbMessages] = useState(0);
    const [isEscape, setIsEscape] = useState(false);

    let inc = 0;
    let adresseEmail = '';

//=========================================================
//  Détection 'escape' et traitement 
//=========================================================

    useEffect(() => {
        const handleEsc = (event) => {
          if (event.keyCode === 27) {
            setIsEscape(true);
          }
        };
        window.addEventListener('keydown', handleEsc);
    
        return () => {
          window.removeEventListener('keydown', handleEsc);
        };
      }, []);
    
    const backModule = () => {
        setTimeout(() => {
          if (isAdmin)
          {
            props.history.push("/clients");
          }
          else
          {
            props.history.push("/");
          }
        }, 100);
      }
    
//=========================================================
//  Fonctions
//=========================================================

    const getMail = () => {
        if (props.history.location.state !== null)
        {
            let userData = props.history.location.state.userData;
            adresseEmail = userData;
            setIsAdmin(true);
        }
        else
        {
            adresseEmail = firebase.getUserEmail();
        }
        setEMail(adresseEmail);
    }

    async function composeMessages() {
        setIsMounted(true);
        inc++;
        if (inc === 1) {
            const messagesClient = await firebase.getMessagesByEmail(adresseEmail);

            let listeTemp = messagesClient.docs;
            listeTemp = listeTemp.map(messageClient => ({
                id: messageClient.id, email: messageClient.data().email, univDate: messageClient.data().univDate, currentDate: messageClient.data().currentDate, 
                objet: messageClient.data().objet, message: messageClient.data().message }));

            setNbMessages(messagesClient.size);
            setListeM(listeTemp);
        }
    }

    async function handleReponse(liste) {
        props.history.push('/reponse', { userData: liste });
    }

//=========================================================

    return (
        <div className="home">
            <Navigation />
            {isEscape && backModule()}
            <div className="container-fluid">
                <div className="row pageTexte">
                    <div className="col-1"></div>
                    <div className="col-9">
                        <h1 className="modelTitre">LISTE DES MESSAGES</h1>
                        <br />
                    </div>
                </div>
                <div className="row">
                    <div className="col-1"></div>
                    <div className="col-7">
                        <div className="pageClient">
                            {!isMounted && getMail()}
                            {!isMounted && composeMessages()}
                            <div></div>
                            <div>
                                <table>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <h4 className="modelEmail">email: {eMail}</h4>
                                            </td>
                                            <td>
                                                <h4 className="modelEspace"> </h4>
                                            </td>
                                            <td>
                                                <h4>Nb de messages: {nbMessages}</h4>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            {listeM.map((listM, id) =>
                                <div key={id}>
                                {isAdmin && <div onClick={() => handleReponse(listM)}>
                                    <label className="sizeMessage">Date:</label><label className="sizeLabel"></label><label>{listM.currentDate}</label><br />
                                    <label className="sizeMessage">Objet:</label><label className="sizeLabel"></label><label>{listM.objet}</label><br />
                                    <textarea className="modelMessage" defaultValue={listM.message}></textarea>
                                </div>}
                                {!isAdmin && <div>
                                    <label className="sizeMessage">Date:</label><label className="sizeLabel"></label><label>{listM.currentDate}</label><br />
                                    <label className="sizeMessage">Objet:</label><label className="sizeLabel"></label><label>{listM.objet}</label><br />
                                    <textarea className="modelMessageNoPointer" defaultValue={listM.message}></textarea>
                                    <br />
                                    <label className="sizeLabel"></label><br />
                                </div>}                                
                            </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Messages;