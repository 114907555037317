import React, { useState, useContext } from 'react'
import { Link } from 'react-router-dom'
import { FirebaseContext } from './Firebase'
import Navigation from "./Navigation";

const Signup = (props) => {

//=========================================================
//  Data
//=========================================================

    // Le contexte Firebase est récupéré grâce à useContext créé dans 
    // le fichier index.js qui lance App 
    // <FirebaseContext.Provider value={new Firebase()}>
    const firebase = useContext(FirebaseContext);

    // Objet qui contient toutes les valeurs saisies
    const data = {
        email: '',
        password: '',
        confirmPassword: ''
    }

    const [loginData, setLoginData] = useState(data);
    const [error, setError] = useState('')

//=========================================================
//  Fonctions
//=========================================================

    // la valeur saisie est mise à jour: on identifie le type de valeur saisie grâce à l'id et la valeur grâce à value
    const handleChange = e => {
        setLoginData({...loginData, [e.target.id]: e.target.value});
    }
   
    // Quand le bouton 'inscription' est cliqué, on met à jour la base de données (email, password) avec la directive set
    // On met à jour l'objet data avec setLoginData
    const handleSubmit = e => {
        // pour éviter le réaffichage
        e.preventDefault();
        const { email, password } = loginData;

        firebase.signupUser(email, password).then
        ((authUser) => {
            if (authUser === 'Erreur: utilisateur déjà inscrit')
            {
                alert('Erreur: utilisateur déjà inscrit');
            }
        //  Effacer email et password
            setLoginData({...data});
            setError('');

            setTimeout(() => {
                props.history.push('/')
            }, 500)

        })
        
    }

    const { email, password, confirmPassword } = loginData;

    // Le bouton 'inscription' n'apparaît que lorsque les conditions sont remplies
    const btn = email === '' || password === '' || password !== confirmPassword 
    ? <button disabled>Inscription</button> : <button>Inscription</button>

    // gestion erreurs
    const errorMsg = error !== '' && <span>{error.message}</span>;

    const tailleEcran = () => {
        return (window.innerWidth > 576 ? true : false )
    }

//=========================================================

    return (
        <div className="connexion">
            <Navigation />
            {tailleEcran() &&
            <div className="signUpLoginBox">
                <div className="slContainer">
                    <div className="formBoxLeftSignup"></div>
                    <div className="formBoxRight">
                        <div className="formContent">

                            {errorMsg}

                            <h1>Inscription</h1>
                            <form onSubmit={handleSubmit}>
                                <div className="inputBox">
                                    <input onChange={handleChange} value={email} type="email" id="email" autoComplete="off" required />
                                    <label htmlFor="email">Email</label>
                                </div>

                                <div className="inputBox">
                                    <input onChange={handleChange} value={password} type="password" id="password" autoComplete="off" required />
                                    <label htmlFor="password">Mot de passe</label>
                                </div>

                                <div className="inputBox">
                                    <input onChange={handleChange} value={confirmPassword} type="password" id="confirmPassword" autoComplete="off" required />
                                    <label htmlFor="confirmPassword">Confirmer le mot de passe</label>
                                </div>

                                {btn}
                            </form>
                            <div className="linkContainer">
                                <Link className="simpleLink" to="/login">Déjà inscrit? Connectez-vous.</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            }
            {!tailleEcran() &&
            <div className="signUpLoginBox2">
                <div className="slContainer">
                    <div className="formBoxRight">
                        <div className="formContent">

                            {errorMsg}

                            <h1>Inscription</h1>
                            <form onSubmit={handleSubmit}>
                                <div className="inputBox2">
                                    <input onChange={handleChange} value={email} type="email" id="email" autoComplete="off" required />
                                    <label htmlFor="email">Email</label>
                                </div>

                                <div className="inputBox2">
                                    <input onChange={handleChange} value={password} type="password" id="password" autoComplete="off" required />
                                    <label htmlFor="password">Mot de passe</label>
                                </div>

                                <div className="inputBox2">
                                    <input onChange={handleChange} value={confirmPassword} type="password" id="confirmPassword" autoComplete="off" required />
                                    <label htmlFor="confirmPassword">Confirmer le mot de passe</label>
                                </div>

                                {btn}
                            </form>
                            <div className="linkContainer">
                                <Link className="simpleLink" to="/login">Déjà inscrit? Connectez-vous.</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            }
        </div>
    )
}

export default Signup
